.firstSection {
  border-bottom: 1px solid;
  p:first-of-type {
    font-size: 36px;
    font-weight: 800;
    color: #683cb4;
    text-shadow: -2px 1px 3px #00000040;
  }
}
.selectChatTypeSection {
  background-color: #e0e0e0;
}
