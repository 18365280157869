.mainVideoContainer {
  // width: 640px;
  // height: 400px;
  width: 100%;
  max-width: 100vw;
  min-height: min(60vh, 400px);
  max-height: max(73vh, 30vw);
  aspect-ratio: 3 / 2;
  background-color: #e0e0e0;
}

.myVideoContainer {
  height: 200px;
  background-color: #e0e0e0;
}

.singleStudentVideoContainer {
  display: grid;
  align-items: center;
  height: 200px;
}

.raiseHand {
  position: relative;
  // border: 2px solid rgb(56 189 248);

  &::before {
    content: '🤚';
    position: absolute;
    z-index: 99;
    top: 0.5rem;
    right: 0.5rem;
    font-size: 2rem;
    animation: wave 2s infinite;
    transform-origin: center bottom;
  }
}

// Custom classes
.edfx-title {
  position: relative;
  color: #08bcd4; // Primary color
  -webkit-text-stroke: 1px #000000; // Black stroke
  width: 165px;
  font-size: 46px;
  height: 36px;
  left: 0px;
  top: 14px;
  text-shadow: 7px 7px 7px rgba(0, 0, 0, 0.5);
}

.presenter-info {
  position: relative;
  top: 69px;
  font-family: 'Jost', sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.mainVideoBox {
  position: relative;
  margin-top: 10px;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
