.mainVideoContainer {
  width: 100%;
  max-width: 100vw;
  min-height: min(60vh, 400px);
  max-height: max(73vh, 30vw);
  aspect-ratio: 3 / 2;
  background-color: #e0e0e0;
}

.myVideoContainerRef {
  height: 200px;
  background-color: #e0e0e0;
}

.raiseHand {
  position: relative;
  // outline: 2px solid rgb(56 189 248);
}

.raiseHand::before {
  content: '🤚';
  position: absolute;
  z-index: 99;
  top: 0.5rem;
  right: 0.5rem;
  font-size: 2rem;
  animation: wave 2s infinite;
  transform-origin: center bottom;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Hover Text */
.raiseHandWrapper {
  position: relative;
  display: inline-block;
}

.raiseHandText {
  position: absolute;
  top: -50px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem 1rem;
  background-color: #e0e0e0; /* Correct color value */
  color: #333;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;
}

.group:hover .raiseHandText {
  opacity: 1;
  visibility: visible;
}

.raiseHandWrapper:hover .raiseHandText {
  opacity: 1;
  visibility: visible;
}
