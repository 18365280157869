@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .font-outline-1 {
    -webkit-text-stroke: 1px black;
  }
    .font-outline-2 {
      -webkit-text-stroke: 2px black;
    }
    .font-outline-4 {
      -webkit-text-stroke: 4px black;
    }
  }